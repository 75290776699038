/* eslint-disable no-unused-expressions */
<template>
  <div>
    <div class="intro-y flex flex-col sm:flex-row items-center mt-8">
      <h2 class="text-lg font-medium mr-auto">最新消息</h2>
      <div class="w-full sm:w-auto flex mt-4 sm:mt-0">
        <div
          v-show="!grid.isEditing && grid.isRowSelected"
          id="batch-dropdown"
          class="dropdown ml-auto mr-2 sm:ml-0"
        >
          <button
            class="dropdown-toggle button px-2 box bg-blue-500 text-white flex items-center"
          >
            <FontAwesome icon="tasks" type="fas" class="w-4 h-4 mr-1" />
            群組操作
          </button>
          <div class="dropdown-box">
            <div class="dropdown-box__content box dark:bg-dark-1 p-2 w-32">
              <span
                class="flex items-center block p-2 transition duration-300 ease-in-out bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2 rounded-md"
                @click="grid.removeSelectedRows"
              >
                <FontAwesome icon="trash" type="fas" class="w-4 h-4 mr-2" />
                刪除
              </span>
            </div>
          </div>
        </div>
        <button
          v-show="!grid.isEditing"
          class="button text-white bg-theme-1 shadow-md mr-2 flex items-center"
          @click="grid.addNewRow"
        >
          <FontAwesome icon="plus" type="fas" class="w-4 h-4 mr-1" />
          新增
        </button>
      </div>
    </div>
    <!-- BEGIN: HTML Table Data -->
    <div class="intro-y box p-5 mt-5">
      <div class="flex flex-col sm:flex-row sm:items-end xl:items-start">
        <div class="xl:flex sm:mr-auto">
          <div class="sm:flex items-center ml-1 sm:mr-2 mt-2 xl:mt-0">
            <input
              type="text"
              class="input w-full sm:w-40 xxl:w-full mt-2 sm:mt-0 border"
              v-model.trim="grid.keyword"
              placeholder="請輸入關鍵字"
            />
          </div>
          <div class="mt-2 xl:mt-0">
            <button
              type="button"
              class="button w-full sm:w-16 bg-theme-1 text-white"
              @click="grid.refresh"
            >
              搜尋
            </button>
          </div>
        </div>
        <div class="flex mt-2 sm:mt-0"></div>
      </div>
      <div class="overflow-x-auto scrollbar-hidden mt-2">
        <grid
          ref="grid"
          v-bind="gridOptions"
          @addNewRow="
            (row, callback) => {
              initData(row);
              callback();
            }
          "
          @removeSelectedRows="onGridRemoveSelectedRows"
        >
          <template #modal="{ row, submit, reset }">
            <vxe-form
              ref="form"
              v-bind="formOptions"
              :data="row"
              @reset="reset"
              @submit="submit"
            >
              <template #columns-photo="{ data }">
                <div class="grid grid-cols-12 w-full">
                  <div class="col-span-12 mb-8 justify-self-center">
                    <FileUploader
                      id="news-photo-uri"
                      style="min-width:470px;max-width:470px;min-height:200px;max-height:200px;"
                      mode="image"
                      :modelValue="data.Photo?.Uri"
                      :action="uploadAction"
                      :autoUpload="true"
                      :limitedHeight="520"
                      :limitedWidth="1200"
                      @update:modelValue="
                        value => {
                          if (data.Photo) data.Photo.Uri = value;
                          else data.Photo = { Uri: value };
                        }
                      "
                      @filter="uploaderFilter"
                    ></FileUploader>
                  </div>
                  <div class="col-span-4">
                    <FileUploader
                      id="news-photo-thumbnail-uri"
                      style="min-width:300px;max-width:300px;min-height:300px;max-height:300px;"
                      class="mx-auto"
                      mode="image"
                      :modelValue="data.Photo?.ThumbnailUri"
                      :action="uploadAction"
                      :autoUpload="true"
                      :limitedHeight="300"
                      :limitedWidth="300"
                      @update:modelValue="
                        value => {
                          if (data.Photo) data.Photo.ThumbnailUri = value;
                          else data.Photo = { ThumbnailUri: value };
                        }
                      "
                      @filter="uploaderFilter"
                    ></FileUploader>
                  </div>
                  <div class="col-span-8">
                    <div class="grid grid-cols-2 gap-1">
                      <div class="w-full">
                        <label>新聞分類</label>
                        <div class="mt-1 w-full" flex>
                          <vxe-select
                            placeholder="請選擇分類"
                            :options="category"
                            :modelValue="data.CategoryId"
                            @update:modelValue="
                              value => (data.CategoryId = value)
                            "
                          ></vxe-select>
                        </div>
                      </div>
                      <div class="w-full">
                        <label>發佈</label>
                        <div class="mt-1 w-full" flex>
                          <vxe-switch
                            :modelValue="data.Published"
                            @update:modelValue="
                              value => (data.Published = value)
                            "
                          ></vxe-switch>
                        </div>
                      </div>
                      <div class="w-full">
                        <label>發佈日期</label>
                        <div class="mt-1" flex>
                          <vxe-input
                            type="date"
                            placeholder="請輸入標題"
                            :modelValue="data.ReleaseDate"
                            @update:modelValue="
                              value => (data.ReleaseDate = value)
                            "
                          ></vxe-input>
                        </div>
                      </div>
                      <div class="w-full">
                        <label>排序</label>
                        <div class="mt-1 w-full" flex>
                          <vxe-input
                            type="number"
                            placeholder="請輸入標題"
                            :modelValue="data.Ordinal"
                            @update:modelValue="value => (data.Ordinal = value)"
                          ></vxe-input>
                        </div>
                      </div>
                      <div class="col-span-2 w-full">
                        <label>上架日期</label>
                        <div class="mt-1" flex>
                          <vxe-input
                            type="date"
                            placeholder="請輸入標題"
                            :modelValue="data.StartDate"
                            @update:modelValue="
                              value => (data.StartDate = value)
                            "
                          ></vxe-input>
                        </div>
                      </div>
                      <div class="col-span-2 w-full">
                        <label>下架日期</label>
                        <div class="mt-1" flex>
                          <vxe-input
                            type="date"
                            placeholder="請輸入標題"
                            :modelValue="data.EndDate"
                            @update:modelValue="value => (data.EndDate = value)"
                          ></vxe-input>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </template>

              <template #columns-content="{ data }">
                <CKEditor
                  v-model="data.Content.Content"
                  @update:modelValue="value => (data.Content.Content = value)"
                  :editor="classicEditor"
                  :config="editorConfig"
                />
              </template>
            </vxe-form>
          </template>
          <template #modal-footer>
            <vxe-button
              type="submit"
              status="primary"
              content="確認"
              @click="
                $refs.form.validate(errMap => {
                  if (errMap === undefined) $refs.form.dispatchEvent('submit');
                })
              "
            ></vxe-button>
            <vxe-button
              type="reset"
              content="重置"
              @click="$refs.form.dispatchEvent('reset')"
            ></vxe-button>
          </template>
          <template #filter-name="{ column, $panel }">
            <div
              v-for="(option, index) in column.filters"
              :key="index"
              class="flex item-center justify-center"
            >
              <input
                type="checkbox"
                class="input input--switch border mt-4 ml-2"
                :checked="option.checked"
                @input="grid.changeFilterEvent(event, option, $panel, true)"
              />
              <div class="relative m-2">
                <div
                  class="absolute rounded-l w-10 h-full flex items-center justify-center bg-gray-100 dark:bg-dark-1 dark:border-dark-4 border text-gray-600"
                >
                  {{ option.label }}
                </div>
                <input
                  v-model.trim="option.data"
                  :disabled="!option.checked"
                  type="text"
                  class="input pl-12 w-full border col-span-4"
                  placeholder="請輸入文字"
                  @input="grid.changeFilterEvent(event, option, $panel)"
                />
              </div>
            </div>
          </template>
        </grid>
      </div>
    </div>
    <!-- END: HTML Table Data -->
  </div>
</template>

<script lang="ts">
/* eslint-disable */
import CloudFun, {
  Condition,
  defineComponent,
  onMounted,
  Operator,
  reactive,
  ref
} from "@cloudfun/core";
import Grid, { GridOptions } from "@/cloudfun/components/Grid.vue";
import { VxeFormProps } from "vxe-table";
import FileUploader from "@/cloudfun/components/FileUploader.vue";
import { VueUploadItem } from "vue-upload-component";
import ClassicEditor from "@/global-components/ckeditor/classic-editor";
import UploadAdapterPlugin from "@/global-components/ckeditor/upload-adapter-plugin";
import formatDate from "xe-utils/toDateString";
export default defineComponent({
  components: {
    Grid,
    FileUploader
  },
  setup() {
    const model = CloudFun.current?.model;
    const grid = ref({});
    const category = ref([]);
    const uploader = ref<any>({});
    var printColumns = [{ field: "Name" }, { field: "Description" }];

    const gridOptions = {
      stripe: false,
      title: "最新消息",
      toolbarConfig: {
        custom: true,
        refresh: true
      },
      printConfig: {
        sheetName: "類別清單",
        columns: printColumns,
        modes: ["current", "selected", "all"]
      },
      exportConfig: {
        filename: "類別清單",
        type: "csv",
        types: ["html", "csv"],
        mode: "all",
        modes: ["current", "selected", "all"],
        columns: printColumns
      },
      columns: [
        {
          field: "Title",
          title: "新聞標題",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true,
          treeNode: true,
          width: "300px",
          editRender: {
            name: "$input",
            immediate: true,
            attrs: { type: "text" }
          }
          // filters: [{ checked: false, label: "包含" }],
          // slots: { filter: "filter-name" }
        },
        {
          field: "CategoryId",
          title: "分類",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true,
          editRender: {
            name: "$select",
            options: category
          }
        },
        {
          field: "ReleaseDate",
          title: "發佈日期",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true,
          formatter: ({ cellValue }: any) =>
            formatDate(new Date(cellValue), "yyyy/MM/dd")
        },
        {
          field: "Published",
          title: "已發佈",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true,
          formatter: ({ cellValue }: any) => (cellValue ? "是" : "否")
        },
        {
          field: "StartDate",
          title: "上架日期",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true,
          formatter: ({ cellValue }: any) =>
            cellValue ? formatDate(new Date(cellValue), "yyyy/MM/dd") : ""
        },
        {
          field: "EndDate",
          title: "下架日期",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true,
          formatter: ({ cellValue }: any) =>
            cellValue ? formatDate(new Date(cellValue), "yyyy/MM/dd") : ""
        },
        {
          field: "Ordinal",
          title: "排序",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true
        }
      ],
      promises: {
        query: model
          ? (params: any) => {
              return model.dispatch("news/query", params);
            }
          : undefined,
        queryAll: model ? () => model.dispatch("news/query") : undefined,
        save: model
          ? (params: any) => model.dispatch("news/save", params)
          : undefined
      },
      modalConfig: { width: "80%", height: "80%", showFooter: true }
    };

    const formOptions = {
      titleWidth: 80,
      titleAlign: "right",
      items: [
        {
          field: "Photo.Uri",
          span: 24,
          slots: { default: "columns-photo" }
        },
        {
          field: "Title",
          title: "新聞標題",
          span: 24,
          itemRender: {
            name: "$input",
            props: { placeholder: "請輸入新聞標題", clearable: true },
            attrs: { type: "text" }
          }
        },
        {
          field: "Introduction",
          title: "新聞短敘",
          span: 24,
          itemRender: {
            name: "$textarea",
            props: { placeholder: "請輸入新聞短敘", clearable: true },
            attrs: { type: "text" }
          }
        },
        {
          field: "Content.Content",
          span: 24,
          slots: { default: "columns-content" }
        }
      ],
      rules: {
        Name: [{ type: "string", required: true }]
      }
    };

    onMounted(() => {
      var params = { condition: new Condition("Type", Operator.Equal, 0) };
      model?.dispatch("category/query", params).then(
        payload => {
          category.value = payload.map((el: any) => {
            return { label: el.Name, value: el.Id };
          });
        },
        failure => {
          CloudFun.send("error", { subject: "操作失敗！", content: failure });
        }
      );
    });

    function schemaCustomization(editor: any) {
      editor.model.schema.extend("$block", {
        allowAttributes: "classList"
      });
      editor.conversion.attributeToAttribute({
        model: {
          key: "classList"
        },
        view: {
          key: "class"
        }
      });
    }

    const classicEditor = ClassicEditor;
    const editorConfig = {
      extraPlugins: [UploadAdapterPlugin, schemaCustomization],
      toolbar: {
        shouldNotGroupWhenFull: true,
        items: [
          "heading",
          "|",
          "fontFamily",
          "fontSize",
          "fontColor",
          "fontBackgroundColor",
          "|",
          "bold",
          "italic",
          "underline",
          "bulletedList",
          "numberedList",
          "outdent",
          "indent",
          "highlight",
          "insertTable",
          "|",
          "link",
          "blockQuote",
          "imageInsert",
          "mediaEmbed",
          // 'codeBlock',
          "htmlEmbed",
          "|",
          "|",
          "undo",
          "redo"
        ]
      },
      removePlugins: ["Markdown"],
      image: {
        toolbar: [
          "imageTextAlternative",
          "imageStyle:full",
          "imageStyle:side",
          "linkImage"
        ]
      },
      table: {
        contentToolbar: [
          "tableColumn",
          "tableRow",
          "mergeTableCells",
          "tableCellProperties",
          "tableProperties"
        ]
      },
      heading: {
        options: [
          {
            model: "paragraph",
            title: "Paragraph",
            class: "ck-heading_paragraph"
          },
          {
            model: "heading1",
            view: { name: "h1", classes: "font-bold text-xl" },
            title: "Heading 1",
            class: "ck-heading_heading1"
          },
          {
            model: "heading2",
            view: { name: "h2", classes: "font-bold text-base" },
            title: "Heading 2",
            class: "ck-heading_heading2"
          }
        ]
      }
    };

    return {
      grid,
      gridOptions,
      formOptions,
      category,
      uploader,
      uploadAction: `${process.env.VUE_APP_BACKEND_URL}/api/files`,
      classicEditor,
      editorConfig
    };
  },
  methods: {
    onGridRemoveSelectedRows(rows: any, callback: any) {
      cash("#batch-dropdown").dropdown("hide");
      callback();
    },
    initData(row: any) {
      row.Published = false;
      row.Ordinal = 0;
      row.Title = "";
      row.ReleaseDate = new Date();
      row.Ordinal = 0;
      row.Published = false;
      row.Content = {
        Content: ""
      };
    },
    async uploaderFilter(
      current: VueUploadItem,
      original: VueUploadItem,
      prevent: any
    ) {
      if (!current || !current.name) return prevent();
      if (!/\.(png|gif|jpg|jpeg)$/i.test(current.name)) {
        alert("未支援此種圖片格式");
        return prevent();
      }
    }
  }
});
</script>
<style>
.ck .ck .ck-content {
  min-height: 400px !important;
}
</style>
