
/* eslint-disable */
import CloudFun, {
  Condition,
  defineComponent,
  onMounted,
  Operator,
  reactive,
  ref
} from "@cloudfun/core";
import Grid, { GridOptions } from "@/cloudfun/components/Grid.vue";
import { VxeFormProps } from "vxe-table";
import FileUploader from "@/cloudfun/components/FileUploader.vue";
import { VueUploadItem } from "vue-upload-component";
import ClassicEditor from "@/global-components/ckeditor/classic-editor";
import UploadAdapterPlugin from "@/global-components/ckeditor/upload-adapter-plugin";
import formatDate from "xe-utils/toDateString";
export default defineComponent({
  components: {
    Grid,
    FileUploader
  },
  setup() {
    const model = CloudFun.current?.model;
    const grid = ref({});
    const category = ref([]);
    const uploader = ref<any>({});
    var printColumns = [{ field: "Name" }, { field: "Description" }];

    const gridOptions = {
      stripe: false,
      title: "最新消息",
      toolbarConfig: {
        custom: true,
        refresh: true
      },
      printConfig: {
        sheetName: "類別清單",
        columns: printColumns,
        modes: ["current", "selected", "all"]
      },
      exportConfig: {
        filename: "類別清單",
        type: "csv",
        types: ["html", "csv"],
        mode: "all",
        modes: ["current", "selected", "all"],
        columns: printColumns
      },
      columns: [
        {
          field: "Title",
          title: "新聞標題",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true,
          treeNode: true,
          width: "300px",
          editRender: {
            name: "$input",
            immediate: true,
            attrs: { type: "text" }
          }
          // filters: [{ checked: false, label: "包含" }],
          // slots: { filter: "filter-name" }
        },
        {
          field: "CategoryId",
          title: "分類",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true,
          editRender: {
            name: "$select",
            options: category
          }
        },
        {
          field: "ReleaseDate",
          title: "發佈日期",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true,
          formatter: ({ cellValue }: any) =>
            formatDate(new Date(cellValue), "yyyy/MM/dd")
        },
        {
          field: "Published",
          title: "已發佈",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true,
          formatter: ({ cellValue }: any) => (cellValue ? "是" : "否")
        },
        {
          field: "StartDate",
          title: "上架日期",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true,
          formatter: ({ cellValue }: any) =>
            cellValue ? formatDate(new Date(cellValue), "yyyy/MM/dd") : ""
        },
        {
          field: "EndDate",
          title: "下架日期",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true,
          formatter: ({ cellValue }: any) =>
            cellValue ? formatDate(new Date(cellValue), "yyyy/MM/dd") : ""
        },
        {
          field: "Ordinal",
          title: "排序",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true
        }
      ],
      promises: {
        query: model
          ? (params: any) => {
              return model.dispatch("news/query", params);
            }
          : undefined,
        queryAll: model ? () => model.dispatch("news/query") : undefined,
        save: model
          ? (params: any) => model.dispatch("news/save", params)
          : undefined
      },
      modalConfig: { width: "80%", height: "80%", showFooter: true }
    };

    const formOptions = {
      titleWidth: 80,
      titleAlign: "right",
      items: [
        {
          field: "Photo.Uri",
          span: 24,
          slots: { default: "columns-photo" }
        },
        {
          field: "Title",
          title: "新聞標題",
          span: 24,
          itemRender: {
            name: "$input",
            props: { placeholder: "請輸入新聞標題", clearable: true },
            attrs: { type: "text" }
          }
        },
        {
          field: "Introduction",
          title: "新聞短敘",
          span: 24,
          itemRender: {
            name: "$textarea",
            props: { placeholder: "請輸入新聞短敘", clearable: true },
            attrs: { type: "text" }
          }
        },
        {
          field: "Content.Content",
          span: 24,
          slots: { default: "columns-content" }
        }
      ],
      rules: {
        Name: [{ type: "string", required: true }]
      }
    };

    onMounted(() => {
      var params = { condition: new Condition("Type", Operator.Equal, 0) };
      model?.dispatch("category/query", params).then(
        payload => {
          category.value = payload.map((el: any) => {
            return { label: el.Name, value: el.Id };
          });
        },
        failure => {
          CloudFun.send("error", { subject: "操作失敗！", content: failure });
        }
      );
    });

    function schemaCustomization(editor: any) {
      editor.model.schema.extend("$block", {
        allowAttributes: "classList"
      });
      editor.conversion.attributeToAttribute({
        model: {
          key: "classList"
        },
        view: {
          key: "class"
        }
      });
    }

    const classicEditor = ClassicEditor;
    const editorConfig = {
      extraPlugins: [UploadAdapterPlugin, schemaCustomization],
      toolbar: {
        shouldNotGroupWhenFull: true,
        items: [
          "heading",
          "|",
          "fontFamily",
          "fontSize",
          "fontColor",
          "fontBackgroundColor",
          "|",
          "bold",
          "italic",
          "underline",
          "bulletedList",
          "numberedList",
          "outdent",
          "indent",
          "highlight",
          "insertTable",
          "|",
          "link",
          "blockQuote",
          "imageInsert",
          "mediaEmbed",
          // 'codeBlock',
          "htmlEmbed",
          "|",
          "|",
          "undo",
          "redo"
        ]
      },
      removePlugins: ["Markdown"],
      image: {
        toolbar: [
          "imageTextAlternative",
          "imageStyle:full",
          "imageStyle:side",
          "linkImage"
        ]
      },
      table: {
        contentToolbar: [
          "tableColumn",
          "tableRow",
          "mergeTableCells",
          "tableCellProperties",
          "tableProperties"
        ]
      },
      heading: {
        options: [
          {
            model: "paragraph",
            title: "Paragraph",
            class: "ck-heading_paragraph"
          },
          {
            model: "heading1",
            view: { name: "h1", classes: "font-bold text-xl" },
            title: "Heading 1",
            class: "ck-heading_heading1"
          },
          {
            model: "heading2",
            view: { name: "h2", classes: "font-bold text-base" },
            title: "Heading 2",
            class: "ck-heading_heading2"
          }
        ]
      }
    };

    return {
      grid,
      gridOptions,
      formOptions,
      category,
      uploader,
      uploadAction: `${process.env.VUE_APP_BACKEND_URL}/api/files`,
      classicEditor,
      editorConfig
    };
  },
  methods: {
    onGridRemoveSelectedRows(rows: any, callback: any) {
      cash("#batch-dropdown").dropdown("hide");
      callback();
    },
    initData(row: any) {
      row.Published = false;
      row.Ordinal = 0;
      row.Title = "";
      row.ReleaseDate = new Date();
      row.Ordinal = 0;
      row.Published = false;
      row.Content = {
        Content: ""
      };
    },
    async uploaderFilter(
      current: VueUploadItem,
      original: VueUploadItem,
      prevent: any
    ) {
      if (!current || !current.name) return prevent();
      if (!/\.(png|gif|jpg|jpeg)$/i.test(current.name)) {
        alert("未支援此種圖片格式");
        return prevent();
      }
    }
  }
});
